.sn_breadcrumb {
	font-size: 0;
	text-transform: lowercase;
	font-weight: fw(regular);
	padding-top: 30px;
	padding-bottom: 15px;

	span {
		vertical-align: top;
		display: inline-block;
	}

	a {
		color: $body-color;
		font-size: 14px;

		&:hover,
		&:active {
			color: $primary;
		}

		& + span {

			&::before {
				content: "/";
				font-size: 14px;
				color: $body-color !important;
			}
		}

		& + .breadcrumb_last {
			display: inline-block;
			font-size: 14px;
			max-width: 140px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

}
