// Based on http://codepen.io/javasteve99/pen/hDxpn
@mixin font-face($font-family, $file-path, $font-weight: normal, $font-style: normal){
  @font-face{
    font-family: $font-family;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype'), url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
  // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
  @media screen and (-webkit-min-device-pixel-ratio: 0){
    @font-face{
      font-family: $font-family;
      src: url('#{$file-path}.svg##{$font-family}') format('svg');
    }
  }
}

@mixin font-face-woff($font-family, $file-path, $font-weight: normal, $font-style: normal){
  @font-face{
    font-family: $font-family;
    src: url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.woff') format('woff');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@function fw($font-weight) {
	$value: map-get($font-weights, $font-weight);
	@return $value;
}
