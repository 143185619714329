.sn_grid_items {
	padding: 75px 0 (100px - 40px);
	background: $gray-200;

	&._bg_dark {
		background: $gray-900;
	}

	&_ct {
		transform: translateY(100px);
		opacity: 0;
		transition: $transition-smooth;

		&._in {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}
