$font-path: '../fonts';

@import 'https://fonts.googleapis.com/css?family=Raleway:300,400,700&display=swap';

@include font-face-woff('BebasNeue', '../fonts/bebas/bebasneue_thin-webfont', 100);
@include font-face-woff('BebasNeue', '../fonts/bebas/bebasneue_light-webfont', 300);
@include font-face-woff('BebasNeue', '../fonts/bebas/bebasneue_book-webfont', 400);
@include font-face-woff('BebasNeue', '../fonts/bebas/bebasneue_regular-webfont', 500);
@include font-face-woff('BebasNeue', '../fonts/bebas/bebasneue_bold-webfont', 700);

