.sn_comments {

	&_title {
		@include font-size(20px, 30px);
		// font-family: $font-family-serif;
		font-style: italic;
		line-height: 1.1;
	}

	&_i {
		margin-top: 30px;

		&:not(._parent) {
			padding-left: 20px;

			@include mq(md){
				padding-left: 50px;
			}

			@include mq(lg){
				padding-left: 100px;
			}
		}

		&_in {
			display: flex;

			figure {
				@include size(35px);
				margin: 0 15px 0 0;
				flex-shrink: 0;

				@include mq(md){
					@include size(45px);
					margin: 0 20px 0 0;
				}

				@include mq(lg){
					@include size(68px);
					margin: 0 25px 0 0;
				}
			}

			.author {
				@include font-size(18px, 20px);
				margin-bottom: 5px;
				line-height: 1;

				@include mq(md){
					margin-bottom: 10px;
				}
			}

			p {
				@include font-size(14px, 16px);
				font-weight: fw(light);
				line-height: 1.3;
				margin: 0;
			}

			.footer {
				@include font-size(12px, 14px);
				color: #979799;
				font-weight: fw(light);
				line-height: 1.3;
				margin-top: 10px;

				@include mq(md){
					margin-top: 15px;
				}

				.date {
					&::after {
						content: '|';
						margin: 0 10px;
					}
				}

				a {
					color: #979799;
					text-transform: uppercase;

					&:hover,
					&:active {
						color: $primary;
					}
				}
			}
		}
	}
}
