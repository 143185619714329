.sn_sponsors {
	background-color: $gray-400;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 55px 20px 15px;

	@include mq(md) {
		padding: 55px 40px 15px;
	}

	@include mq(lg) {
		padding: 55px 60px 15px;
	}

	@include mq(xl) {
		padding: 55px 120px 15px;
	}

	&._in {
		.sn_sponsors_i {
			opacity: 1;
		}
	}

	&_i {
		display: block;
		width: percentage(1/2);
		text-align: center;
		margin-bottom: 20px;
		padding: 0 10px;
		transition: $transition-smooth;
		opacity: 0;

		@include mq(sm) {
			padding: 0 20px;
			margin-bottom: 40px;
			width: percentage(1/3);
		}

		@include mq(md) {
			width: percentage(1/5);
		}

		@include mq(lg) {
			width: percentage(1/6);
		}

		@include mq(xl) {
			width: percentage(1/7);
		}

		@for $i from 1 through 40 {
			&:nth-child(#{$i}) {
				transition-delay: .02s * $i;
			}
		}

		a {
			display: inline-block;
			opacity: .9;
			filter: grayscale(100%);

			&:hover {
				opacity: 1;
				filter: grayscale(0);
			}

			img {
				@extend .img-fluid;
			}
		}
	}
}
