.sn_intro_slider {
	position: relative;

	&._tall {
		height: 100vh;
		min-height: 480px;
		@include mq(md){ min-height: 600px; }
		@include mq(lg){ min-height: 720px; }
	}

	&._medium {
		height: 480px;
		@include mq(md){ height: 600px; }
		@include mq(lg){ height: 750px; }
	}

	&._short {
		height: 350px;
		@include mq(md){ height: 380px; }
		@include mq(lg){ height: 400px; }
	}

	// Gradient
	&::before {
		@include gradient-y(rgba(#000, .35), rgba(#000, 0));
		content: '';
	  position: absolute;
		top: 0; left: 0; right: 0;
		height: 50%;
		pointer-events: none;
		z-index: 2;
	}

	&_bg {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;

		&._align_bottom {
			align-items: flex-end;
		}
	}

	&_content {
		text-align: center;
		padding: 15px;

		@include mq(md){
			padding: 15px 60px;
		}

		&, * {
			color: #fff;
		}

		h1, h2, h3, h4, h5, h6 {
			@extend .display-1;
			font-weight: fw(regular);
		}

		img {
			display: inline-block;
			max-width: 100%;
		}

		._align_bottom & {
			padding-bottom: 80px;
			@include mq(md){ padding-bottom: 100px; }
			@include mq(lg){ padding-bottom: 130px; }
		}
	}

	&_video {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;

		.plyr {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;

			.plyr__video-wrapper {
				height: 100%;
				padding: 0 !important;
			}
		}
	}

	.swiper-container {
		height: 100%;

		.swiper-wrapper {
			.swiper-slide {
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			display: none;

			@include mq(md){
				display: block;
			}
		}
	}
}
