.sn_block_slider_image_links {
	background: $gray-200;
	padding: 65px 0;

	&_ct {
		transform: translateY(100px);
		opacity: 0;
		transition: $transition-smooth;

		&._in {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	&_title {
		@extend .h1;
		text-align: center;
		color: $body-color;
		margin-bottom: 30px;
	}

	&_i {
		// (s)n_(b)lock_(s)lider_(i)mage_(l)inks_(i)
		$sbsili: &;

		@include scut-ratio-box;
		display: block;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;

		&::after {
			@include gradient-y(rgba(#000, 0), #000);
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 220px;
			z-index: 5;

			@include mq(md){
				height: 170px;
			}

			@include mq(lg){
				height: 220px;
			}
		}

		&:hover,
		&:active {
			#{$sbsili}_in {
				color: $primary;
			}
		}

		&_in {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 10;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: #fff;
			line-height: 1;
			font-family: $bebas;
			text-transform: uppercase;
			font-weight:fw(bold);
			font-size: 18px;
			padding: 28px 21px;
			transition: $transition-base;

			@include mq(md){
				padding: 28px 21px;
			}

			@include mq(xl){
				font-size: 20px;
				padding: 31px 27px;
			}

			.sn_sprite {
				@include size(28px);
				fill: currentColor;
				margin-left: 10px;

				@include mq(md){
					@include size(20px);
				}

				@include mq(xl){
					@include size(35px);
				}
			}
		}
	}

	.swiper-container {
		.swiper-wrapper {
			align-items: stretch;

			.swiper-slide {
				height: auto;
			}
		}

		.swiper-pagination {
			position: relative;
			bottom: 0;
			margin-top: 40px;
			margin-bottom: 8px;
		}

		.swiper-button-prev,
		.swiper-button-next {
			@include size(30px, 30px);
			margin-top: 0;
			top: auto;
			bottom: 0;
			fill: $gray-900;
		}
	}
}
